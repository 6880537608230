import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../images/logo.png";

const Home = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/quienes-somos');
        }, 5000); 
        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div>
            <header className="home-loader">
                <img src={logo} className="App-logo" alt="logo" />
            </header>
        </div>
    );
};

export default Home;