import React from 'react';
import logo from "../images/logo.png";
import "../css/footer.css";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <img 
            src={logo} 
            alt="Logo" 
            className="footer-logo"
          />
          <div className="contact-info">
            <p>📍 Ubicación: Panamá</p>
          </div>
        </div>
        
        <div className="footer-section">
          <h3>Enlaces Rápidos</h3>
          <ul>
            <li><a href="/quienes-somos">Quiénes Somos</a></li>
            <li><a href="/productos">Productos</a></li>
            <li><a href="/remesas">Remesas</a></li>
            <li><a href="/contacto">Contacto</a></li>
          </ul>
        </div>
        
        <div className="footer-section">
          <h3>Síguenos</h3>
          <div className="social-links">
            <a href="https://www.facebook.com/SolucionesCrypto" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="https://www.instagram.com/solucionescrypto/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.youtube.com/@Soluciones-Crypto" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-youtube"></i>
            </a>
          </div>
          <p>&copy; 2024 Sistema de Pagos. Todos los derechos reservados.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer; 