import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import "../css/wallet.css";
import App from "react-modal-playground-sym-link";
import 'react-modal-playground-sym-link/dist/index.css';

function Wallet() {
  return (
    <div className="wallet-page">
      <Navbar />
      <div className="wallet-content">
        <App />
      </div>
      <Footer />
    </div>
  );
}

export default Wallet; 