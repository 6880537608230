import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import "../css/guias-tutoriales.css";
import Footer from '../components/Footer';
import VideoModal from '../components/VideoModal';
import { useLocation } from 'react-router-dom';

function GuiasTutoriales() {
  const location = useLocation();
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    if (location.state?.videoToPlay) {
      setSelectedVideo(location.state.videoToPlay);
    }
  }, [location]);

  const videosData = [
    {
      id: 1,
      videos: [
        {
          titulo: "👉 Cómo Usar el Sistema de Referidos en Crypto Soluciones 🎯 | Guía Paso a Paso",
          videoId: "IywMTIkG-fU",
          descripcion: "¿Quieres ganar más con Crypto Soluciones? 💰 En este video te mostramos cómo funciona nuestro sistema de referidos y cómo puedes aprovecharlo al máximo para obtener recompensas. 🚀"
        },
        {
          titulo: "👉 Cómo Iniciar Sesión en tu Wallet de Crypto Soluciones 🚀 | Guía Rápida",
          videoId: "FPqtv-LfQFc",
          descripcion: "Aprende paso a paso cómo iniciar sesión de manera fácil y segura en tu Wallet desde nuestra plataforma  Soluciones Crypto. En este video te mostramos el proceso completo, desde el acceso a la Wallet hasta la verificación de tu cuenta. 💻🔒"
        },
        {
          titulo: "✅ Tutorial Completo: Cómo Usar Tu Wallet en Crypto Soluciones 💼 | Paso a Paso",
          videoId: "d6x5oZnJ9ec",
          descripcion: "¡Bienvenidos al tutorial más completo para usar tu wallet en Soluciones Crypto ! 🎉 Aprende todo lo que necesitas saber para gestionar tus criptomonedas de manera fácil y segura."
        },
        {
          titulo: "💳 Cómo Puedes Adquirir Nuestra Tarjeta Black Card de Soluciones Crypto | Tutorial Completo 🚀",
          videoId: "-XUxLyxJ6m0",
          descripcion: "Quieres disfrutar de todos los beneficios de nuestra tarjeta Black Card? 💳 En este tutorial te mostramos paso a paso cómo adquirirla de manera rápida y sencilla desde nuestra plataforma Crypto Soluciones."
        }
      ]
    }
  ];

  const handleVideoClick = (videoId) => {
    setSelectedVideo(videoId);
  };

  const handleCloseModal = () => {
    setSelectedVideo(null);
  };

  return (
    <div className="guias-container">
      <Navbar />
      <div className="content">
        <h1>Guías y Tutoriales</h1>
        <div className="guias-section">
          {videosData.map((categoria) => (
            <div key={categoria.id} className="categoria-card">
              <h2>{categoria.categoria}</h2>
              <div className="videos-grid">
                {categoria.videos.map((video, index) => (
                  <div 
                    key={index} 
                    className="video-card"
                    onClick={() => handleVideoClick(video.videoId)}
                  >
                    <div className="thumbnail-container">
                      <img 
                        src={`https://img.youtube.com/vi/${video.videoId}/maxresdefault.jpg`}
                        alt={video.titulo}
                        className="video-thumbnail"
                      />
                      <div className="play-overlay">
                        <i className="fas fa-play"></i>
                      </div>
                    </div>
                    <div className="video-info">
                      <h3>{video.titulo}</h3>
                      <p>{video.descripcion}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedVideo && (
        <VideoModal 
          videoId={selectedVideo} 
          onClose={handleCloseModal}
        />
      )}
      <Footer />
    </div>
  );
}

export default GuiasTutoriales; 